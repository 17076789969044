import React from 'react';
import Layout from '../../layout/pl';
import TeamPerson from '../../components/TeamPerson';

import Karolina from '../../images/team/karolina-krempa.png';

const handleClick = () => {
	window.medicall_modal("793f22c1230b16122b04");
	return false;
};

const certification = [];
const KarolinaKrempa = (props) => {
	return (
		<Layout {...props}>
			<TeamPerson img={Karolina} handleClick={handleClick} certification={certification} person="karolina" />
		</Layout>
	);
};
export default KarolinaKrempa;
